import {Box, Center, Text} from "@chakra-ui/react";
import {LogoHeader} from "../components/home-contents/LogoHeader";
import {Description} from "../components/home-contents/Description";
import {Mission} from "../components/home-contents/Mission";
import {SignupForm} from "../components/home-contents/SignupForm";
import {useRef} from "react";
import {Team} from "../components/home-contents/Team";

const Home = () => {

    const signUpRef = useRef(null);

    return (
        <Box bg='black'>
            <LogoHeader scrollRef={signUpRef}/>

            {/*<Description/>*/}

            {/*<Mission/>*/}

            {/*<Team/>*/}

            <SignupForm signUpRef={signUpRef} />

            <Center h='80px'>
                <Text fontSize='sm' color='white'>© 2025 by Spoonful Studios.</Text>
            </Center>
        </Box>
    );
}

export default Home;
