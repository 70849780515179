import { extendTheme } from '@chakra-ui/react'
import '@fontsource/prompt';

const theme = extendTheme({
    styles: {
        global: {
            html: {
                scrollBehavior: "smooth",
            },
        }
    },
    fonts: {
        heading: `'NanHoloGigawide', sans-serif`,
        body: `'Prompt', sans-serif`,
    },
})

export default theme