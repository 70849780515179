import {Box, Text, VStack, Input, HStack, Button, Heading, Textarea} from "@chakra-ui/react";
import {useState} from "react";

export const SignupForm = ({signUpRef}) => {

    const [email, setEmail] = useState("")
    const [subscribeChecked, setSubscribeChecked] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();

        setSubmitting(true)

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                setSubmitting(false)
                setSubmitted(true)
            })
            .catch((error) => console.log(error))
    };

    return (
        <Box bg='black' py={16} px={[6, 12]} ref={signUpRef} color='white'>
            <VStack gap={14} align='left'>
                <Heading textAlign={['left', 'left']} fontSize={['18pt', '30pt']} fontWeight={900} lineHeight={1.2} w={{base:'75%', md:'50%'}}>
                    LET'S HAVE SOME ICE CREAM!
                </Heading>

                <Box
                    name='sign-up'
                    as='form'
                    data-netlify="true"
                    onSubmit={handleSubmit}
                >
                    <Input
                        type='hidden'
                        name='form-name'
                        value='sign-up'
                    />

                    {!submitted ?
                        <VStack w={{base:'100%', md:'60%'}} gap={[6, 4]} align='left'>
                            <Input
                                variant='outline'
                                rounded='none'
                                borderColor='red.500'
                                placeholder='Email'
                                type='email'
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                name="sign-up-email"
                                id='sign-up-email'
                                required={true}
                                _hover={{borderColor: 'red.400'}}
                            />
                            <Textarea
                                variant='outline'
                                rounded='none'
                                borderColor='red.500'
                                placeholder='Message'
                                type='text'
                                name="sign-up-message"
                                id='sign-up-message'
                                _hover={{borderColor: 'red.400'}}
                            >
                            </Textarea>

                            <Button
                                variant='outline'
                                type='submit'
                                bg='black'
                                borderColor='red.500'
                                textColor='red.500'
                                rounded='none'
                                size='md'
                                w={['40%', '30%']}
                                _hover={{bg: 'blackAlpha.800'}}
                                isLoading={submitting}
                            >
                                Send
                            </Button>
                        </VStack>
                        :
                        <Text textAlign='left' fontSize='12pt' fontWeight={400} lineHeight={1.2}>
                            Thank you - we'll be in touch!
                        </Text>}
                </Box>
                {/*{!submitted ?*/}
                {/*    <Box mt='-30px'>*/}
                {/*        <Checkbox*/}
                {/*            onChange={(e) => setSubscribeChecked(e.target.checked)}*/}
                {/*        >*/}
                {/*            Yes, subscribe me to your newsletter*/}
                {/*        </Checkbox>*/}
                {/*    </Box> :*/}
                {/*    null*/}
                {/*}*/}


                {/*<Box>*/}
                {/*    <VStack gap={4} align='left'>*/}
                {/*        <Text textAlign='left' fontSize='12pt' fontWeight={400} lineHeight={1.2}>*/}
                {/*            We are actively looking for unique new ideas – <br/>*/}
                {/*            If you are a creator with a story to tell, get in touch!*/}
                {/*        </Text>*/}

                {/*        <Text textAlign='left' fontSize='12pt' fontWeight={400} cursor='pointer'*/}
                {/*              textDecoration='underline'>*/}
                {/*            creators@spoonful-studios.com*/}
                {/*        </Text>*/}
                {/*    </VStack>*/}
                {/*</Box>*/}
            </VStack>
        </Box>
    )
}