import {Box, Image, Center, chakra, Heading, Text, Link, Icon, VStack} from "@chakra-ui/react";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import {motion} from 'framer-motion'
import {isMobile} from 'react-device-detect'
import {useRef} from "react";

export const LogoHeader = ({scrollRef}) => {
    return (
        <Box
            bg='black'
            w='98vw'
            h='100vh'
        >

                <Box position='relative' h='100%'>
                    <Image w={{base:'80%', md:'50%'}} src='images/spoonful_logo.png' position='absolute'
                           top={{base:'25%', md:'50%'}} left={{base:'50%', md:'10%'}}
                           transform={{base:'translate(-50%, -50%)', md:'translate(-0%, -50%)'}} zIndex={100}/>
                    <Image w={{base:'80%', md:'50%'}} src='images/spoonful_of_ice_cream.png' position='absolute'
                           top={{base:'25%', md:'50%'}} left={{base:'50%', md:'45%'}}
                           transform={{base:'translate(-50%, -0%)', md:'translate(-0%, -50%)'}}/>

                    <VStack position='absolute' top={{base:'80%', md:'80%'}} left='50%' transform='translate(-50%, -50%)' w='100%'>
                    <Text color='white' fontSize={['11pt', '14pt']} align='center'>
                        We're working hard to get our site ready. In the meantime, get in touch!
                    </Text>
                    <Icon as={HiOutlineArrowNarrowDown} color='white' boxSize={6}/>
                    </VStack>
                </Box>

        </Box>
    );
}
