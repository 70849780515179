import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ChakraProvider} from '@chakra-ui/react'
import '@fontsource-variable/montserrat'
import '@fontsource/prompt';
import Fonts from './fonts';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <Fonts/>
            <App/>
        </ChakraProvider>
    </React.StrictMode>
);

